/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import parser, { DomElement } from 'html-react-parser';
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';

import { HtmlParserRule } from 'Component/Html/Html.type';
import Template from 'Component/Template';

class HtmlComponentPlugin {
    rules = (originalMap: HtmlParserRule[], instance: { replacePara: any; parserOptions: any }) => ([
        ...originalMap,
        {
            query: { name: ['aostemplate'] },
            replace: (params: DomElement) => this.replaceTemplate(params, instance),
        },
    ]);

    /**
     * Replace `aostemplate` to `Template`
     * @param DomElement
     * @param instance
     * @returns {*}
     */
    replaceTemplate(
        { attribs, children }: DomElement,
        instance: { replacePara: any; parserOptions: any },
    ): JSX.Element {
        return (
            <Template { ...attributesToProps(attribs) }>
                { domToReact(children, instance.parserOptions) }
            </Template>
        );
    }
}

const { rules } = new HtmlComponentPlugin();

const replaceDivHTML = (
    args: any,
    callback: any,
    instance: any,
): JSX.Element => {
    const [DomEl] = args;
    const { attribs, children } = DomEl;

    if (attribs?.['data-content-type'] === 'html') {
        const htmlChildren = children[0]?.data?.replaceAll('&lt;', '<')?.replaceAll('&gt;', '>');

        if (htmlChildren) {
            return (
                <div { ...attribs }>
                    { parser(htmlChildren, instance.parserOptions) }
                </div>
            );
        }
    }

    return callback(DomEl, instance);
};
export default {
    'Component/Html/Component': {
        'member-property': {
            rules,
        },
        'member-function': {
            replaceDiv: replaceDivHTML,
        },
    },
};
