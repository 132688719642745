import AOS from 'aos';
import { useEffect } from 'react';

import { TemplateComponentProps } from './Template.type';

import './Template.style';

// https://github.com/michalsnik/aos#animations

/** @namespace Zuporder/Component/Template/Component/TemplateComponent */
export const TemplateComponent = ({ children, isBlock = false }: TemplateComponentProps): JSX.Element => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    if (!isBlock) {
        return (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
                { children }
            </>
        );
    }

    return (
        <div block="Template">
            { children }
        </div>
    );
};

export default TemplateComponent;
