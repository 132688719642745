/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
export const RENDER_NEWSLETTER = 'render_newsletter';

export const NEWSLETTER_COLUMN = {
    title: __('Subscribe Us'),
    columnActiveKey: 'newsletterActive',
    items: [
        {
            render: RENDER_NEWSLETTER,
        },
    ],
};

export const COLUMN_MAP = [
    NEWSLETTER_COLUMN,
    {
        title: __('Company'),
        items: [
            {
                href: '/about-us',
                title: __('About Us'),
            },
        ],
    },
    {
        title: __('Customer Support'),
        items: [
            {
                href: '/disclaimer-and-warrenty',
                title: __('Disclaimer and Warrenty'),
            },
            {
                href: '/privacy-policy-cookie-restriction-mode',
                title: __('Privacy Policy & Cookies'),
            },
            {
                href: '/returns-and-cancellation',
                title: __('Returns and Cancellation'),
            },
            {
                href: '/shipping-and-delivery',
                title: __('Shipping and Delivery'),
            },
            {
                href: '/terms-and-conditions',
                title: __('Terms of Use'),
            },
        ],
    },
    {
        title: __('Popular categories'),
        items: [
            {
                href: '/apparels',
                title: __('Apparels'),
            },
            {
                href: '/yarn',
                title: __('Yarn'),
            },
            {
                href: '/fabric',
                title: __('Fabric'),
            },
        ],
    },
    {
        title: __('Account'),
        items: [
            {
                href: '/customer/account/login',
                title: __('Log In'),
            },
            {
                href: '/customer/account/create',
                title: __('Register'),
            },
        ],
    },
];
